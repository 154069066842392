
/*
** Service  End-Point Configuration
*/
console.log('Loading configuration based on hostname directive: %s', window.location.hostname.toLowerCase())

// DO-NOT EDIT SERVICE ENDPOINTS MANUALY. THEY ARE CONTROLED BY THE ENV VARS
// SPECIFIED IN THE DOCKER-COMPOSE FILE
var service_endpoints = {
  baseurl_apm: process.env.REACT_APP_BASEURL_APM
  , baseurl_cms: process.env.REACT_APP_BASEURL_CMS
  , baseurl_geo: process.env.REACT_APP_BASEURL_GEO
  , baseurl_ups: process.env.REACT_APP_BASEURL_UPS
  , baseurl_sqm: process.env.REACT_APP_BASEURL_SQM
  , baseurl_consent: process.env.REACT_APP_BASEURL_CONSENT
  /*  , baseurl_geomarker: "http://10.0.0.7:9091" */
  , baseurl_keycloak: process.env.REACT_APP_BASEURL_KEYCLOAK
  , baseurl_metering: process.env.REACT_APP_BASEURL_METERING
  , baseurl_notification: process.env.REACT_APP_BASEURL_NOTIFY
  , baseurl_druid: process.env.REACT_APP_BASEURL_PINPOINT
  , baseurl_rqe: process.env.REACT_APP_BASEURL_REPORTS_QUERYENGINE
  , baseurl_reportsmanager: process.env.REACT_APP_BASEURL_REPORTS_MANAGER
  , baseurl_reportstemplate: process.env.REACT_APP_BASEURL_REPORTS_TEMPLATE
  , baseurl_spreadmap: process.env.REACT_APP_BASEURL_SPREADMAP
  , baseurl_trigger: process.env.REACT_APP_BASEURL_TRIGGER
}

/*
** Helper function
*/
Array.prototype.contains = function (obj) {
  return this.indexOf(obj) > -1
}

/*
** Set Proxy Configuration if HTTP Request Domain Name matches the following...
*/
if (
  ['gitex.eureka.ai', 'demo.eureka.ai', 'airtel.eureka.ai','devint.eureka.local','lvod.eureka.ai'].contains(
    window.location.hostname.toLowerCase()
  )
) {
  console.log('[*] Using Proxy Configuration...')

  for (var key in service_endpoints) {
    if (service_endpoints.hasOwnProperty(key)&&(key!='baseurl_keycloak')) {
      service_endpoints[key] = ''
    }
  }
}

/*
** Logging Service-Endpoint configuration to console.log
*/
console.log('[*] Service-Endpoint Configuration:');
for (var key in service_endpoints) {
  if (service_endpoints.hasOwnProperty(key)) {
    console.log(key, service_endpoints[key]);
  }
}


var config = {
  // Landing Page could be one of pinpoint, explorer, splash, instantcheck, matchbox and some more.
  LandingPage: 'verification',
  Auth: {
    Keycloak: {
      realm: 'spectrum',
      url: service_endpoints['baseurl_keycloak'],
//      url: 'https://lvod.eureka.ai/auth',
      'ssl-required': 'external',
      clientId: 'spectrum-ux',
      'public-client': true,
      'verify-token-audience': true,
      'use-resource-role-mappings': true,
      'confidential-port': 0,
    },
  },
  Dashboard: {
    reportsQueryEngineUrl: service_endpoints['baseurl_rqe'],
    reportsManagerUrl: service_endpoints['baseurl_reportsmanager'],
    CUBEJS_TOKEN:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODcwNDIzODEsImV4cCI6MTU4NzEyODc4MX0.nZC3KXN157NAJVKc4b9UWI0YnExEChbeRqMcx007Sbg',
    segments: { Audience: 'user_profile_indosat_small_18_dev_02' },
  },
  PinPoint: {
    url_sormas: 'http://10.0.0.7:6080/sormas-ui/login#!cases/',
    urlDruid_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v1/getData',
    urlDruidProfile_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v2/profile',
    urlDruidV2_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v2',
    urlElastic_Hexo: service_endpoints['baseurl_ups'] + '/services/ups/res/latest/geo/h3/search',
    urlDruid_SQL: service_endpoints['baseurl_druid'] + '/eureka/druid/v1/sql/getData',
    urlOpenStreetMap:
      'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=Zr39nwGNk6dCteI7sZ6W',
    urlMapTiler: 'https://api.maptiler.com/maps/',
    paramMapTiler: '/256/{z}/{x}/{y}.png?&key=Zr39nwGNk6dCteI7sZ6W',
    urlElastic_Geo:
      service_endpoints['baseurl_ups'] + '/eureka/ups/elasticSearch/geo_json_malaysia/_search',
    urlElastic_ndb: service_endpoints['baseurl_ups'] + '/eureka/ups/elasticSearch/ref_ndb/_search',
    urlGeomarker: service_endpoints['base_url_geomarker'] + '/services/pinpoint/rest/v2/geomarker',
    urlNetwork:
      service_endpoints['base_url_geomarker'] + '/services/pinpoint/rest/v2/geomarker-network',
    urlBoundaries: service_endpoints['baseurl_geo'] + '/services/geo/res/v1/geo/h3',
    urlGeoDruidSearch:
      service_endpoints['baseurl_geo'] + '/service/geo/rest/latest/geo/movement/search',
    urlGeoPoiExposureCount:
      service_endpoints['baseurl_geo'] + '/service/geo/rest/latest/poi/exposurecount',
    urlHexagonDetails: service_endpoints['baseurl_geo'] + '/services/geo/res/v1/geo/h3/hexagon',
    urlGeoServiceGraphQl: service_endpoints['baseurl_geo'] + '/graphql',
    urlSpreadMap: service_endpoints['baseurl_spreadmap'] + '/v1',
    useDAUMinus: 2,
    useAreaMeasurement: false,
    useSubAreaBorder: false,
    useCurrentDate: false,
    dateProfileStart: '2020-01-01',
    dateProfile: '2020-05-30',
    datePeriodDiseaseStart: '2020-05-01',
    datePeriodDisease: '2020-05-30',
    datePeriodPOI: '2020-02-16/2020-02-17',
    dsUserPOI: 'ftr_user_poi_daily_indonesia_covid19',
    dsUserHexagon: 'ftr_user_hexagon_daily_indonesia_covid19',
    dsUserExposureScore: 'ftr_user_health_scores_indonesia_covid19',
    dsUserEsiEmi: 'emi_subs_agg_indonesia_covid19',
    dsHexagonEsiEmi: 'emi_hex_agg_indonesia_covid19',
    contactTraceConfig: {
      resolution: 10,
      hexagonColumnName: 'hexagon_id_10',
      granularity: 'day',
      defaultDateRange: ['2020-01-01', '2020-01-08'],
      dwellTimeFilter: 1.0,
    },
    dsCatPOI: 'ref_poi_hexo_jakarta',
    dsDashboard: 'db_disease_outbreak_indonesia_covid19',
    dbHexagonsDeseaseOutbreak: 'db_hexagon_disease_outbreak_indonesia_covid19',
    defaultResolution: 9,
    initBoundaryRes: 8,
    shapeFile: 'indonesia-centroid.geojson',
    mapStart: [-1.72339668, 124.18142891],
    dashboardStart: [-1.72339668, 135.18142891],
    zoomLevel: 5,
    landingHeatmap: true,
    useFeatureEmiasi: false,
    useUserExposure: true,
    useFeatureCovidSpread: true,
    useFeaturePeopleMovement: true,
    useFeatureCaseManagement: false,
    country: 'indonesia',
    metric_granularity: 'province',
    dimension: 'province',
    provinceLabel: 'province',
    districtLabel: 'district',
    districtKey: 'district',
    subDistrictLabel: 'sub_district',
    villageLabel: 'village',
    subDistrictKey: 'subDistrict',
    villageKey: 'village',
    districtGroupLabel: 'District',
    subDistrictGroupLabel: 'Sub District',
    villageGroupLabel: 'Village',
    primaryTerritoryPlaceholder: 'Select District',
    secondaryTerritoryPlaceholder: 'Select Sub District / Village',
    useDashboardHourly: false,
    useSubdistrictLevel: true,
    useGeojsonZip: true,
    initialLandingArea: {
      label: 'JAKARTA PUSAT',
      value: 'JAKARTA PUSAT_133',
      key: 'district',
      provinceValue: 'DKI JAKARTA_16',
    },
    showForecast: true,
    useSpreadMapTopLevel: 'Province',
    showMovementMetric: true,
    provinceGroupLabel: 'Province',
    initialLandingDefaultZoom: 12,
    useDensityTopLevel: true,
    useDensityInterval: false,
    useSecondDropdownArea: true,
    showDownloadAllCSVNetworkBtn: true,
    useSpecificProfince: false,
    useSpecificDistrict: false,
    disableHighResolution: false,
  },
  Explorer: {
    primaryIndexName: 'user_profile_indosat_small_18_dev_02',
    segmentQueryManager: {
      url: service_endpoints['baseurl_sqm'],
      namedQuery: '/services/sqm/rest/latest/namedQuery',
      getAllNamedQuery: '/services/sqm/rest/latest/getAllNamedQuery',
      getNamedQueryForIndex: '/services/sqm/rest/latest/namedQuery/index',
    },
    upsManager: {
      url: service_endpoints['baseurl_ups'],
      elasticSearch: '/services/ups/rest/latest/users/elasticSearch',
      getWhiteList: '/services/ups/rest/latest/users/getWhiteList',
      getLookalikeWhiteList: '/services/ups/rest/latest/lookalike/getWhiteList',
    },
  },
  MatchBox: {
    campaignService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/campaign/v1',
    },
    telcoService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/telco/v1',
    },
    regionService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/country/v1',
    },
    clientService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/client/v1',
    },
  },
  InstantCheck: {
    primaryIndexName: 'user_profile_indosat_small_18_dev_02',
    creditScoreMetadataIndexName:
      'credit_score_metadata_indosat_small_1.8_dev_0.1',
    addressIndexName: 'user_address_indosat_small_1.8_dev_0.1',
    upsElasticSearch: {
      url: service_endpoints['baseurl_ups'] + '/services/ups/rest/latest/users/elasticSearch',
    },
    singleScoreService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest',
    },
    batchScoreService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest/batch',
    },
    getAddressService: {
      url: service_endpoints['baseurl_ups'] + '/services/addresscheck/rest/latest',
    },
    getRiskScoreProfileService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest/profile',
    },
    getAddressProfileService: {
      url: service_endpoints['baseurl_ups'] + '/services/addresscheck/rest/latest/profile',
    },
  },
  AccountPermissionManager: {
    manageAccounts: {
      url: service_endpoints['baseurl_apm'] + '/services/authz/rest/v1.0/apm/account',
    },
    manageUsers: {
      url: service_endpoints['baseurl_apm'] + '/services/authz/rest/v1.0/apm/user',
    },
    manageProducts: {
      url: service_endpoints['baseurl_apm'] + '/services/authz/rest/v1.0/apm/product',
    },
    manageRoles: {
      url: service_endpoints['baseurl_apm'] + '/services/authz/rest/v1.0/apm/role',
    },
  },
  Metering: {
    indexName: 'ups-service-logs',
    meteringService: {
      url: service_endpoints['baseurl_metering'] + '/services/ups/rest/latest/metering',
    },
  },
  DefaultService: {
    notificationService: {
      url: service_endpoints['baseurl_notification'],
      getNotificationUrl: '/services/notification/rest/v1/alert/',
    },
  },
  triggerService: {
    url: service_endpoints['baseurl_trigger'] + '/services/airflow/rest/v1/trigger',
  },
  triggerTemplate: {
    url: service_endpoints['baseurl_reportstemplate'] + '/odata/templates'
  },
  reportsManagerService: {
    url: service_endpoints['baseurl_reportsmanager'] + '/services/reports/rest/latest/report',
  },
  ConsentManager: {
    getConsent: service_endpoints['baseurl_consent'] + '/services/consent/rest/latest/userid/',
  },
  Verification: {
    startMonth: '2020-01-01',
    bulkRequestDelay: 50,
    urlDruid_API: service_endpoints.baseurl_geo + '/services/geo/rest/latest',
    instaCheck: service_endpoints.baseurl_ups + '/services/ups/validate/rest/latest',
    instaCheckCoordinate: service_endpoints.baseurl_ups + '/services/ups/validate/rest/latest/location/validate_coordinates',
    consentDateInputFormat: 'DD/MM/YYYY HH:mm',
    bulkUploadLimit: 5000
  }
}
export default config
